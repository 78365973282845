<template>
    <div :id="`gridId${dataSource.gridIndex}`">
        <div class="aa-common-search">
            <div class="search-title">{{dataSource.title}}</div>
            <el-button class="search-btn" type="primary">查询</el-button>
            <el-input class="search-input" clearable v-model="keyWord" @change="handleSearchChanged(dataSource.gridIndex)"></el-input>
        </div>
        <el-table class="aa-common-grid" ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" border :span-method="objectSpanMethod" @selection-change="handleSelectionChange" max-height="500">
            <el-table-column type="selection" width="42">
            </el-table-column>
            <el-table-column v-for="(item, index) in columns" :key="index" :prop="item.prop" :label="item.label" show-overflow-tooltip>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import columns1 from './monitor_data_header'
import columns2 from './assess_data_header'
import columns3 from './related_data_header'
import { mapActions } from 'vuex'
// 定义全局变量
let keyword1 = "";
export default {
    data() {
        return {
            columns: null,
            columns1,
            columns2,
            columns3,
            tableData: [],
            multipleSelection: [],
            countArr: [],
            dest: [],
            keyWord: '',
        }
    },
    props: {
        dataSource: {
            type: Object,
            default: () => { }
        }
    },
    watch: {
        dataSource() {
            this.handleDataSource();
        }
    },
    mounted() {
        // this.handleDataSource();
    },
    methods: {
        ...mapActions([
            'GetJcdxList', //获取监测对象列表
            'GetPgsjList', //获取评估数据列表
            'GetXgsjList', //获取相关数据列表
        ]),
        handleDataSource() {
            this.initData();
            switch (this.dataSource.gridIndex) {
                case '0':
                case '1':
                    this.columns = this.columns1;
                    this.getTableData1('');
                    break;
                case '2':
                    this.columns = this.columns2;
                    this.getTableData2('');
                    break;
                case '3':
                    this.columns = this.columns3;
                    this.getTableData3('');
                    break;
            }
        },
        handleSearchChanged(val) {
            this.initData();
            switch (val) {
                case '0':
                case '1':
                    this.getTableData1(this.keyWord);
                    break;
                case '2':
                    this.getTableData2(this.keyWord);
                    break;
                case '3':
                    this.getTableData3(this.keyWord);
                    break;
            }
        },
        initData(){
            this.tableData = [];
            this.dest = [];
        },
        async getTableData1(Key) {
            let _this = this;
            let Isfiltrate = '';
            let IncludeBhid = '';
            // if(this.dataSource.dxlx==='4' && !this.dataSource.Ycyszc) return;
            if(this.dataSource.dxlx === '1'){
                Isfiltrate = '1';
                if(this.dataSource.type !== '0'){
                    IncludeBhid = _this.dataSource.JcdxInfos[0];
                }
            }
            let result = await this.GetJcdxList({
                Key: Key, //String 内存放监测对象名称
                Ycysbm: this.dataSource.Ycysbm, //string 内存放遗产要素编码
                Ycyszc: this.dataSource.Ycyszc,
                Isfiltrate: Isfiltrate, //string 传"1"时为筛选掉已做过评估对象类型为病害的病害
                IncludeBhid: IncludeBhid, //string 修改评估对象类型为病害时，传入所选择的病害id，用逗号分隔
            });
            if (result && result.length > 0) {
                result.forEach(item => {
                    this.dest.push(item);
                    if (item && item.JCDXLIST && item.JCDXLIST.length > 0) {
                        _this.handleTableData1(item, item.JCDXLIST);
                    }
                })

                if (this.dataSource.type === '0') {
                    if (this.dataSource.dxlx === '3') {
                        this.toggleSelection(this.tableData);
                    }
                    if(this.dataSource.isPgjl){
                        this.setDataSelected();
                    }
                } else {
                    this.setDataSelected();
                }
            }
            this.setStyle();
        },
        setDataSelected(){
            let _this = this;
            let dataSelected = [];
            if (this.dataSource.JcdxInfos && this.dataSource.JcdxInfos.length > 0) {
                _this.dataSource.JcdxInfos.forEach(ele1 => {
                    _this.tableData.forEach(ele2 => {
                        if (ele2.ID === ele1) {
                            dataSelected.push(ele2);
                        }
                    })
                })
            }
            this.toggleSelection(dataSelected);
        },
        handleTableData1(val, child) {
            if (child && child.length > 0) {
                child.forEach(item => {
                    let rowObj = {
                        YCYSBM: val.YCYSBM,
                        YCYSMC: val.YCYSMC,
                        ID: item.ID,
                        DXBID: item.DXBID,
                        DXMC: item.DXMC,
                        DXLX: item.DXLX,
                    };
                    this.tableData.push(rowObj);
                })
            }
        },
        async getTableData2(Key) {
            let result = await this.GetPgsjList({
                Key: Key
            });
            if (result && result.length > 0) {
                result.forEach(item => {
                    this.dest.push(item);
                    if (item && item.JCDXLIST && item.JCDXLIST.length > 0) {
                        this.handleTableData2(item, item.JCDXLIST);
                    }
                })

                if (this.dataSource.type === '0') {
                    this.toggleSelection(this.tableData);
                } else {
                    this.setDataSelected();
                }
            }
            this.setStyle();
        },
        handleTableData2(val, child) {
            if (child && child.length > 0) {
                child.forEach(item => {
                    let rowObj = {
                        YCYSBM: val.YCYSBM,
                        YCYSMC: val.YCYSMC,
                        ID: item.ID,
                        PGDXLX: item.PGDXLX,
                        PGDXLXMC: item.PGDXLXMC,
                        PGDXMC: item.PGDXMC,
                        PGDXMS: item.PGDXMS,
                    };
                    this.tableData.push(rowObj);
                })
            }
        },
        async getTableData3(Key) {
            let result = await this.GetXgsjList({
                Key: Key
            });
            if (result && result.length > 0) {
                result.forEach(item => {
                    this.dest.push({
                        YCYSBM: item.JCLX,
                        JCDXLIST: item.JCDXLIST
                    })
                    if (item && item.JCDXLIST && item.JCDXLIST.length > 0) {
                        this.handleTableData3(item, item.JCDXLIST);
                    }
                })

                if (this.dataSource.type !== '0') {
                    this.setDataSelected();
                }
            }
            this.setStyle();
        },
        handleTableData3(val, child) {
            if (child && child.length > 0) {
                child.forEach(item => {
                    let rowObj = {
                        YCYSBM: val.JCLX,
                        ID: item.ID,
                        ZBXMC: item.ZBXMC,
                    };
                    this.tableData.push(rowObj);
                })
            }
        },
        // eslint-disable-next-line no-unused-vars
        objectSpanMethod({ row, column, rowIndex, columnIndex }) {
            if (this.dest.length > 1) {
                if (columnIndex === 2) {
                    if (keyword1 != row.YCYSBM) {
                        keyword1 = row.YCYSBM;
                        return [this.groupNumber(keyword1), 1];
                    } else return [0, 0];
                }
            } else if (this.dest.length === 1 && this.dest[0].JCDXLIST.length > 1) {
                if (columnIndex === 2) {
                    if (rowIndex === 0) {
                        return [this.dest[0].JCDXLIST.length, 1];
                    } else return [0, 0];
                }
            }
        },
        // 返回合并的组内的条数
        groupNumber(val) {
            for (let i = 0; i < this.dest.length; i++) {
                if (val == this.dest[i].YCYSBM) return this.dest[i].JCDXLIST.length;
            }
        },
        toggleSelection(rows) {
            if (rows) {
                rows.forEach(row => {
                    this.$refs.multipleTable.toggleRowSelection(row);
                });
            } else {
                this.$refs.multipleTable.clearSelection();
            }
        },
        handleSelectionChange(val) {
            if(val && val.length === 0){
                this.multipleSelection = [];
            }else{
                let oneSelected = [];
                if (this.dataSource.gridIndex === '0' && this.dataSource.dxlx === '1') {
                    if (val && val.length > 0) {
                        if (val.length > 2) {
                            this.multipleSelection = [];
                            this.$refs.multipleTable.clearSelection();
                        } else {
                            oneSelected.push(val[val.length - 1]);
                            this.multipleSelection = oneSelected;
                            if (val.length > 1) {
                                this.$refs.multipleTable.clearSelection();
                                this.$refs.multipleTable.toggleRowSelection(val[val.length - 1]);
                            }
                        }
                    }
                } else {
                    this.multipleSelection = val;
                }
            }
            
            this.$emit("handleSelectionChange", this.multipleSelection)
        },
        setStyle() {
            setTimeout(() => {
                // eslint-disable-next-line no-undef
                let tableHeads = $('.has-gutter');
                if (tableHeads && tableHeads.length > 0) {
                    for (let i = 0; i < tableHeads.length; i++) {
                        tableHeads[i].children[0].children[0].style.borderRight = 'none';
                    }
                }

                // eslint-disable-next-line no-undef
                let tableRows = $('.el-table__row');
                if (tableRows && tableRows.length > 0) {
                    for (let i = 0; i < tableRows.length; i++) {
                        tableRows[i].children[0].style.borderRight = 'none';
                    }
                }
            }, 200)
        }
    }
}
</script>
<style lang="scss" scoped>
.aa-common-search {
    position: relative;
    width: 100%;
    margin: 16px 0;
    .search-title {
        height: 30px;
        line-height: 30px;
        color: #2a3987;
        font-size: 15px;
    }
    .search-btn {
        position: absolute;
        top: 1px;
        right: 0;
        height: 28px;
    }
    .search-btn.el-button--primary {
        color: #FFF;
        background-color: #2a63d5;
        border-color: #2a63d5;
    }
    .search-input {
        position: absolute;
        top: 0;
        right: 80px;
        width: 200px;
    }
    .search-btn.el-button{
        line-height: 0px;
    }
    
}
</style>
